<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="page-wrap">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        待签发
      </div>
    </top-bar-arrow>
    <div class="main">
      <div class="list-wrap">
        <div class="list-scroll-div">
          <template v-for="(item, index) in goodsList">
            <div class="item-wrap" :key="index">
              <div class="item" @click="singleChoose(item)">
                <div class="left">
                  <div class="icon" :class="{'active': isSingleActive(item)}"></div>
                </div>
                <div class="center">
                  <div class="img">
                    <img :src="item.small_image_url" mode="" />
                  </div>
                  <div class="text">{{ item.product_name }}</div>
                </div>
                <div class="right">￥{{ parseInt(item.product_market_price) }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="total-line box-shadow">
        <div class="left" @click="allSelectChange">
          <div class="icon" :class="{'active': allSelectActive()}"></div>
        </div>
        <div class="center">
          合计
          <span class="num">{{ getTotal() }}</span>
          元
        </div>
        <div class="right">
          <div class="btn-save" @click="openSign">
            <div class="btn-content">签发</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 签发弹框 -->
    <van-popup v-model="show">
      <div class="qf-confirm-modal panel-modal">
        <div class="content">
          <div class="title">签发确认</div>
          <div class="main">商品一旦签发，即不可回收，您是否仍要签发？</div>
          <div class="btn-line">
            <div class="btn-normal" @click="closeModal">取消</div>
            <div class="btn-save">
              <div class="btn-content"  @click="signOrder">
                确认
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex'
  import topBarArrow from '../../components/top-bar-arrow'
  export default {
    components: {
      topBarArrow
    },
    data() {
      return {
        chooseItem: [],
        show: false
      }
    },
    computed: {
      ...mapGetters({
        goodsList: 'getGoodsList',
        curGoodsList: 'getCurGoodsList'
      })
    },
    watch: {
      curGoodsList: {
        handler (now) {
          this.chooseItem = JSON.parse(JSON.stringify(now))
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {
    },
    methods: {
      ...mapMutations(['UPDATE_CUR_GOODLIST']),
      // 选中状态
      isSingleActive (item) {
        return this.chooseItem.findIndex(choose => choose.id == item.id) > -1
      },
      // 全选状态
      allSelectActive () {
        return this.goodsList.length == this.chooseItem.length
      },
      // 单选
      singleChoose (item) {
        let index = this.chooseItem.findIndex(choose => choose.id == item.id)
        if (index == -1) {
          this.chooseItem.push(item)
        } else {
          this.chooseItem.splice(index, 1)
        }
      },
      // 全选
      allSelectChange () {
        this.chooseItem = (this.goodsList.length == this.chooseItem.length) ? [] : [...this.goodsList]
      },
      // 计算金额
      getTotal () {
        let sum = 0
        this.chooseItem.forEach(item => {
          sum = sum + parseFloat(item.product_market_price_float)
        })
        return Math.round(sum * 100) / 100
      },
      // 签发
      openSign () {
        if (this.chooseItem.length > 0) {
          this.show = true
        }
      },
      closeModal () {
        this.show = false
      },
      signOrder () {
        this.show = false
        this.UPDATE_CUR_GOODLIST(this.chooseItem)
        this.$router.push({
          name: 'sign'
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import './waitSign.scss'
</style>

